import { IonicSafeString } from '@ionic/angular';
import { Menu } from './menu';
import { ImageObject } from './image';
import { Video } from './video';

export class Page {
	id?: string;
	title: string;
	slug: string;
	banner?: ImageObject;
	teaser?: string;
	categoryId?: string;
	createdAt?: firebase.firestore.Timestamp;
	modifiedAt?: firebase.firestore.Timestamp;
	publishedAt?: firebase.firestore.Timestamp;
	state: PageState;
	components?: Array<PageComponent>;
	showContentTools?: boolean;
	keywords?: Array<String>;
	order?: number;
	isOpen?: boolean;
}

export enum PageState {
	Draft = 0,
	Published = 1,
}

export class PageComponent {
	id?: string;
	type: ComponentType;
  image?: ImageObject;
  isFloating?: boolean;
  float?: string;
	isEnabled?: boolean;
	stringContent?: string;
	text?: string;
	htmlContent?: IonicSafeString;
  video?: Video;
  menu?: Menu;
	order: number;
}

export enum ComponentType {
	Heading = 0,
	SubHeading = 1,
	Html = 2,
	Video = 3,
  Menu = 4,
  Image = 5
}

export class Category {
  id?: string;
  title: string;
  slug: string;
}

export class FeaturedLink {
	id?: string;
	image?: ImageObject;
	url: string;
	text: string;
	isVisible: boolean;
	order: number;
}

export class HomeSlide {
	id?: string;
	image?: ImageObject;
	url?: string;
	isVisible: boolean;
	order: number;
}

export class AboutContent {
	id?: string;
	roomId: string;
  buttonUrl: string;
	buttonText: string;
	order?: number;
	isVisible?: boolean;
	fileType: FileType;
}

export enum FileType {
	Video = 0,
	Pdf = 1,
	Powerpoint = 2,
	Image = 3,
	Link = 4,
}

export class PageSurvey extends Page {
	survey?: Array<PageSurveyQuestions>;
}

export class PageSurveyQuestions {
	id?: string;
	question: string;
}

export class PageSurveySubmission {
	id?: string;
	createdAt?: firebase.firestore.Timestamp;
	uid: string;
	pageSurveyId: string;
	surveyResultJsonString: string;
}
