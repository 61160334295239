import { Component, OnInit } from '@angular/core';
import { Platform, NavController, MenuController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Subscription } from 'rxjs';

import * as firebase from 'firebase/app';

import { AuthService } from './services/auth.service';

import { IUser } from './models/users.model';
import { MenuItem } from './models/menu';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  fbUser: firebase.User;
  userSubscription: Subscription;
  user: IUser;

  isAdmin = false;
  isModerator = false;

  public selectedIndex = 0;
  public appPages: MenuItem[] = [
    {
      buttonText: 'Dashboard',
      buttonUrl: '/home',
      buttonIcon: 'home',
      routerDirection: 'root',
      isVisible: true,
      requires: ['admin', 'moderator'],
    },
    // {
    //   buttonText: 'Permissions',
    //   buttonUrl: '/permissions',
    //   buttonIcon: 'magnet',
    //   routerDirection: 'root',
    //   isVisible: true,
    // },
    // {
    //   buttonText: 'Guests',
    //   buttonUrl: '/guests',
    //   buttonIcon: 'person-add',
    //   routerDirection: 'root',
    //   isVisible: true,
    //   requires: ['admin'],
    // },
    {
      buttonText: 'Users',
      buttonUrl: '/users',
      buttonIcon: 'people',
      routerDirection: 'root',
      isVisible: true,
      requires: ['admin', 'moderator'],
    },
    {
      buttonText: 'Registered Users',
      buttonUrl: '/registered-users',
      buttonIcon: 'people',
      routerDirection: 'root',
      isVisible: true,
      requires: ['admin', 'moderator'],
    },
    // {
    //   buttonText: 'Rooms',
    //   buttonUrl: '/rooms',
    //   buttonIcon: 'business',
    //   routerDirection: 'root',
    //   isVisible: true,
    //   requires: ['admin'],
    // },
    // {
    //   buttonText: 'Pages',
    //   buttonUrl: '/pages',
    //   buttonIcon: 'newspaper',
    //   routerDirection: 'root',
    //   isVisible: true,
    //   requires: ['admin'],
    // },
    // {
    //   buttonText: 'Agenda',
    //   buttonUrl: '/agenda',
    //   buttonIcon: 'calendar',
    //   routerDirection: 'root',
    //   isVisible: true,
    //   requires: ['admin'],
    // },
    // {
    //   buttonText: 'Invites',
    //   buttonUrl: '/invites',
    //   buttonIcon: 'color-wand',
    //   routerDirection: 'root',
    //   isVisible: true,
    //   requires: ['admin'],
    // },
    // {
    //   buttonText: 'Teams',
    //   buttonUrl: '/teams',
    //   buttonIcon: 'golf',
    //   routerDirection: 'root',
    //   isVisible: true,
    //   requires: ['admin'],
    // },
    // {
    //   buttonText: 'Page Surveys',
    //   buttonUrl: '/page-surveys',
    //   buttonIcon: 'pencil',
    //   routerDirection: 'root',
    //   isVisible: true,
    //   requires: ['admin'],
    // },
    // {
    //   buttonText: 'Team Progress',
    //   buttonUrl: '/team-progress-updates',
    //   buttonIcon: 'hammer',
    //   routerDirection: 'root',
    //   isVisible: true,
    // },
    
    // {
    //   buttonText: 'Questions',
    //   buttonUrl: '/questions',
    //   buttonIcon: 'help-circle',
    //   routerDirection: 'root',
    //   isVisible: true
    // },
    // {
    //   buttonText: 'Surveys',
    //   buttonUrl: '/surveys',
    //   buttonIcon: 'pencil',
    //   routerDirection: 'root',
    //   isVisible: true
    // },
    // {
    //   title: 'Logout',
    //   url: '/logout',
    //   icon: 'heart'
    // },
    // {
    //   title: 'Archived',
    //   url: '/folder/Archived',
    //   icon: 'archive'
    // },
    // {
    //   title: 'Trash',
    //   url: '/folder/Trash',
    //   icon: 'trash'
    // },
    // {
    //   title: 'Spam',
    //   url: '/folder/Spam',
    //   icon: 'warning'
    // }
  ];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private authService: AuthService,
    public navCtrl: NavController,
    public menuCtrl: MenuController
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  ngOnInit() {
    this.authService.getAuthUserObservable().subscribe(
      (data) => {
        this.fbUser = data;
        //console.log(this.fbUser);

        let sessionTimeout = null;
        if (this.fbUser === null) {
          sessionTimeout && clearTimeout(sessionTimeout);
          sessionTimeout = null;
        } else {
          this.fbUser.getIdTokenResult().then((idTokenResult) => {
            // Make sure all the times are in milliseconds!
            const authTime = idTokenResult.claims.auth_time * 1000;
            const sessionDuration = 1000 * 60 * 60 * 8; //8 hours //1000 * 60 * 60 * 24 * 30; -> 1 month
            const millisecondsUntilExpiration = sessionDuration - (Date.now() - authTime);
            sessionTimeout = setTimeout(() => this.logout(), millisecondsUntilExpiration);

            if (idTokenResult.claims.admin) {
              this.isAdmin = true;
              console.log('is admin: ', this.isAdmin);
            }
            if (idTokenResult.claims.moderator) {
              this.isModerator = true;
              console.log('is moderator: ', this.isModerator);
            }
          });
        }

        this.selectIndex();
      },
      (err) => {
        console.log(err);
      }
    );
  }

  logout() {
    this.menuCtrl.close();
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    this.navCtrl.navigateRoot('/logout');
  }

  selectIndex() {
    const path = window.location.pathname;
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(
        (menuItem: MenuItem) => menuItem.buttonUrl === path
      );
    }
  }
}
