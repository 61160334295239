import {
  Component,
  OnInit,
  Input,
  ViewChild,
  AfterViewInit,
  ElementRef,
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CropperComponent } from 'angular-cropperjs';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { Subscription, Observable, combineLatest } from 'rxjs';

import { LoaderService } from '../../services/loader.service';
import { AuthService } from '../../services/auth.service';

import { ImageObject, ImageType, ImageSize } from '../../models/image';

@Component({
  selector: 'app-agenda-image',
  templateUrl: './agenda-image.component.html',
  styleUrls: ['./agenda-image.component.scss'],
})
export class AgendaImageComponent implements OnInit, AfterViewInit {
  @Input() imageData: any;
  @ViewChild('angularCropper', { static: false })
  angularCropper: CropperComponent;
  @ViewChild('content', { static: false }) content: ElementRef;

  image: ImageObject = new ImageObject();

  cropperOptions: any;

  croppedImage: Blob = null;

  scaleValX = 1;
  scaleValY = 1;

  modalLoaded = false;

  pageDataSubscription: Subscription;

  fullImageResize$: Observable<any>;
  thumbnailResize$: Observable<any>;

  tasksCompleted = 0;

  constructor(
    private authService: AuthService,
    private loaderService: LoaderService,
    private modalCtrl: ModalController,
    private ng2ImgMaxService: Ng2ImgMaxService
  ) {
    this.cropperOptions = {
      dragMode: 'crop',
      viewMode: 1,
      aspectRatio: 1,
      autoCrop: true,
      movable: true,
      zoomable: true,
      scalable: true,
      autoCropArea: 1,
      responsive: true,
      minContainerWidth: 600,
      minContainerHeight: 544 - 8,
      minCropBoxWidth: 600,
      minCropBoxHeight: 544 - 8,
      noFileInput: true,
    };
  }

  ngOnInit() {}

  ngAfterViewInit() {
    console.log(this.content);
    this.angularCropper.cropperOptions.minContainerWidth = this.content['el'][
			'clientWidth'
		];
		//this.angularCropper.cropperOptions.minContainerHeight = this.content['el']['offsetHeight'];
  }

  save() {
    this.loaderService.showLoader('Uploading...');
    this.angularCropper.cropper.getCroppedCanvas().toBlob((blob) => {
      const d = new Date();
      const n = d.getTime();
      let blobFile = this.blobToFile(blob, 'tmp.jpg');

      // TODO: think about adding these image dimension to environment file

      this.thumbnailResize$ = this.ng2ImgMaxService.resize([blobFile], 80, 80);
      this.fullImageResize$ = this.ng2ImgMaxService.resize(
        [blobFile],
        256,
        256
      );

      const combineStream = combineLatest(
        this.thumbnailResize$,
        this.fullImageResize$
      );

      this.pageDataSubscription = combineStream.subscribe(
        ([thumbnailResult, fullResult]) => {
          let thumbnailFilename = `agenda-${n}-thumbnail.jpg`;
          this.upload(thumbnailResult, ImageSize.Thumbnail, thumbnailFilename);

          let fullFilename = `agenda-${n}.jpg`;
          this.upload(fullResult, ImageSize.Full, fullFilename);
        }
      );
    });
  }

  upload(blob: Blob, imageSize: ImageSize, filename: string) {
    const self = this;
    //let progress: number = 0;

    const metadata = {
      contentType: 'image/jpeg',
      name: filename,
      cacheControl: 'max-age=31536000',
    };

    let uploadTask = this.authService
      .getStorageRef()
      .child('agenda/' + filename)
      .put(blob, metadata);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        //progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      },
      (err) => {
        self.loaderService.hideLoader();

        switch (err.code) {
          case 'storage/unauthorized':
            // User doesn't have permission to access the object
            break;

          case 'storage/canceled':
            // User canceled the upload
            break;

          case 'storage/unknown':
            // Unknown error occurred, inspect error.serverResponse
            break;
        }

        // TODO: show an alert notifying the user of an error
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          console.log('File available at', downloadURL);
          if (imageSize === ImageSize.Full) {
            self.image.imageName = filename;
            self.image.imageUrl = downloadURL;
            self.image.imageType = ImageType.Jpg;
          } else {
            self.image.thumbnailImageName = filename;
            self.image.thumbnailImageUrl = downloadURL;
            self.image.thumbnailImageType = ImageType.Jpg;
          }

          console.log(self.image);
          self.tasksCompleted++;

          if (self.tasksCompleted === 2) {
            self.loaderService.hideLoader();
            self.dismiss();
          }
        });
      }
    );
  }

  dismiss() {
    this.modalCtrl.dismiss({
      image: this.image,
    });
  }

  cancel() {
    this.dismiss();
  }

  // TODO: move this to a utility service or something
  blobToFile = (theBlob: Blob, fileName: string): File => {
    var b: any = theBlob;
    b.lastModifiedDate = new Date();
    b.name = fileName;

    return <File>theBlob;
  };
}
