import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import * as firebase from 'firebase/app';

import {
  Page,
  PageState,
  Category,
  FeaturedLink,
  HomeSlide,
  AboutContent,
} from '../models/content';

@Injectable()
export class ContentService {
  constructor(private db: AngularFirestore) {}

  getPageBySlug(pageSlug: string): Observable<Page[]> {
    return this.db
      .collection<Page>('pages', (ref) => ref.where('slug', '==', pageSlug))
      .valueChanges();
  }

  getOpenPageBySlug(pageSlug: string): Observable<Page[]> {
    return this.db
      .collection<Page>('open-pages', (ref) =>
        ref.where('slug', '==', pageSlug)
      )
      .valueChanges();
  }

  getPageById(pageId: string): Observable<Page[]> {
    return this.db
      .collection<Page>('pages', (ref) => ref.where('id', '==', pageId))
      .valueChanges();
  }

  getOpenPageById(pageId: string): Observable<Page[]> {
    return this.db
      .collection<Page>('open-pages', (ref) => ref.where('id', '==', pageId))
      .valueChanges();
  }

  getPageByIdPromise(pageId: string): Promise<Page[]> {
    return this.db
      .collection<Page>('pages', (ref) => ref.where('id', '==', pageId))
      .valueChanges()
      .pipe(first())
      .toPromise();
  }

  getOpenPageByIdPromise(pageId: string): Promise<Page[]> {
    return this.db
      .collection<Page>('open-pages', (ref) => ref.where('id', '==', pageId))
      .valueChanges()
      .pipe(first())
      .toPromise();
  }

  getCategoryPagesByCategorySlug(categorySlug: string): Observable<Page[]> {
    return this.db
      .collection<Page>('pages', (ref) =>
        ref
          .where('category', '==', categorySlug)
          .where('state', '==', PageState.Published)
          .orderBy('order', 'asc')
      )
      .valueChanges();
  }

  getCategoryBySlug(categorySlug: string): Observable<Category[]> {
    return this.db
      .collection<Category>('categories', (ref) =>
        ref.where('slug', '==', categorySlug)
      )
      .valueChanges();
  }

  getAllPagesPromise(): Promise<Page[]> {
    return this.db
      .collection<Page>('pages', (ref) => ref.orderBy('createdAt', 'desc'))
      .valueChanges()
      .pipe(first())
      .toPromise();
  }

  getAllOpenPagesPromise(): Promise<Page[]> {
    return this.db
      .collection<Page>('open-pages', (ref) => ref.orderBy('createdAt', 'desc'))
      .valueChanges()
      .pipe(first())
      .toPromise();
  }

  getAllPages(): Observable<Page[]> {
    return this.db
      .collection<Page>('pages', (ref) => ref.orderBy('createdAt', 'desc'))
      .valueChanges();
  }

  getAllOpenPages(): Observable<Page[]> {
    return this.db
      .collection<Page>('open-pages', (ref) => ref.orderBy('createdAt', 'desc'))
      .valueChanges();
  }

  getFeaturedLinks(): Observable<FeaturedLink[]> {
    return this.db
      .collection<FeaturedLink>('featured-links', (ref) =>
        ref.where('isVisible', '==', true).orderBy('order', 'asc')
      )
      .valueChanges();
  }

  getHomeSlides(): Observable<HomeSlide[]> {
    return this.db
      .collection<HomeSlide>('home-slides', (ref) =>
        ref.where('isVisible', '==', true).orderBy('order', 'desc')
      )
      .valueChanges();
  }

  getAboutContent(roomId: string): Observable<AboutContent[]> {
    return this.db
      .collection<AboutContent>('about-content', (ref) =>
        ref.where('roomId', '==', roomId).orderBy('createdAt', 'desc')
      )
      .valueChanges();
  }

  createAboutContent(aboutContent: AboutContent) {
    aboutContent.id = this.db.createId();
    const aboutContentRef = this.db.doc(`about-content/${aboutContent.id}`);
    aboutContentRef.set(JSON.parse(JSON.stringify(aboutContent)));
    return aboutContentRef.update({
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
  }

  createPage(page: Page, isOpen: boolean) {
    page.id = this.db.createId();
    let pageRef;
    if (isOpen === true) {
      pageRef = this.db.doc(`open-pages/${page.id}`);
    } else {
      pageRef = this.db.doc(`pages/${page.id}`);
    }
    
    pageRef.set(JSON.parse(JSON.stringify(page)));
    return pageRef.update({
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      publishedAt: firebase.firestore.FieldValue.serverTimestamp()
    });
  }

  updatePage(page: Page, isOpen: boolean) {
    let pageRef;
    if (isOpen === true) {
      pageRef = this.db.doc(`open-pages/${page.id}`);
    } else {
      pageRef = this.db.doc(`pages/${page.id}`);
    }

    return pageRef.update({
      ...JSON.parse(JSON.stringify(page)),
      modifiedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
  }

  deletePage(page: Page) {
    return this.db.doc<Page>(`pages/${page.id}`).delete();
  }
}
