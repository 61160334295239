import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {
  AngularFireAuthGuard,
  redirectUnauthorizedTo,
  redirectLoggedInTo,
  hasCustomClaim,
} from '@angular/fire/auth-guard';
import { AdminGuard } from './guards/admin.guard';
import { ModeratorGuard } from './guards/moderator.guard';

const adminOnly = () => hasCustomClaim('admin');
const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const redirectLoggedInToItems = () => redirectLoggedInTo(['home']);

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  // {
  //   path: 'folder/:id',
  //   loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  // },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/auth/login/login.module').then((m) => m.LoginPageModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectLoggedInToItems },
  },
  {
    path: 'logout',
    loadChildren: () =>
      import('./pages/auth/logout/logout.module').then(
        (m) => m.LogoutPageModule
      ),
  },
  {
    path: 'verify',
    loadChildren: () =>
      import('./pages/auth/verify/verify.module').then(
        (m) => m.VerifyPageModule
      ),
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomePageModule),
    canActivate: [ModeratorGuard],
    // canActivate: [AdminGuard, AngularFireAuthGuard],
    // data: { authGuardPipe: redirectNonAdminToLogin },
  },
  {
    path: 'unauthorized',
    loadChildren: () =>
      import('./pages/auth/unauthorized/unauthorized.module').then(
        (m) => m.UnauthorizedPageModule
      ),
  },
  {
    path: 'users',
    loadChildren: () =>
      import('./pages/users/users.module').then((m) => m.UsersPageModule),
    canActivate: [ModeratorGuard],
  },
  {
    path: 'chat/:id',
    loadChildren: () =>
      import('./pages/chat/chat.module').then((m) => m.ChatPageModule),
    canActivate: [ModeratorGuard],
  },
  {
    path: 'agenda',
    loadChildren: () =>
      import('./pages/agenda/agenda.module').then((m) => m.AgendaPageModule),
    canActivate: [AdminGuard],
  },
  {
    path: 'pages',
    loadChildren: () =>
      import('./pages/pages/pages.module').then((m) => m.PagesPageModule),
    canActivate: [AdminGuard],
  },
  {
    path: 'about-content/:id',
    loadChildren: () =>
      import('./pages/about-content/about-content.module').then(
        (m) => m.AboutContentPageModule
      ),
    canActivate: [AdminGuard],
  },
  {
    path: 'registered-users',
    loadChildren: () =>
      import('./pages/registered-users/registered-users.module').then(
        (m) => m.RegisteredUsersPageModule
      ),
    canActivate: [ModeratorGuard],
  },
  // {
  //   path: 'permissions',
  //   loadChildren: () => import('./pages/permissions/permissions.module').then( m => m.PermissionsPageModule)
  // }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
