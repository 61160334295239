import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';

import * as firebase from 'firebase/app';

import {
  INedbankClientVerification,
  INedbankClientVerificationRequest,
  IUser,
  IUserProfile,
} from '../models/users.model';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  storageRef: any = firebase.storage().ref();

  constructor(
    private afAuth: AngularFireAuth,
    private db: AngularFirestore,
    private rtdb: AngularFireDatabase,
    private storage: AngularFireStorage
  ) {}

  getStorageRef() {
    return this.storageRef;
  }

  signInEmail(email: string, password: string) {
    return this.afAuth.signInWithEmailAndPassword(email, password);
  }

  signout(): Promise<any> {
    window.localStorage.clear();
    return firebase.auth().signOut();
  }

  getAuthUserObservable(): Observable<firebase.User> {
    return this.afAuth.authState;
  }

  getUser(uid: string): Observable<IUser> {
    return this.rtdb.object<IUser>(`users/${uid}`).valueChanges();
  }

  getUsers(): Observable<IUser[]> {
    return this.rtdb.list<IUser>('users').valueChanges();
  }

  getUserProfiles(limit: number): Observable<IUserProfile[]> {
    return this.rtdb
      .list<IUserProfile>('user-profiles', (ref) =>
        ref.orderByChild('firstName').limitToFirst(limit)
      )
      .valueChanges();
  }

  getAllUsers(): Observable<IUser[]> {
    return this.rtdb
      .list<IUser>('users', (ref) =>
        ref.orderByChild('isNedbankClient').equalTo(true)
      )
      .valueChanges();
  }

  getAllRegisteredUsers(): Observable<IUser[]> {
    return this.rtdb
      .list<IUser>('users', (ref) =>
        ref.orderByChild('isRegisteredNedbankClient').equalTo(true)
      )
      .valueChanges();
  }

  getAllUsersPromise(): Promise<IUser[]> {
    return this.rtdb
      .list<IUser>('users', (ref) => ref.orderByChild('firstName'))
      .valueChanges()
      .pipe(first())
      .toPromise();
  }

  getAllUserProfiles(): Observable<IUserProfile[]> {
    return this.rtdb
      .list<IUserProfile>('user-profiles', (ref) =>
        ref.orderByChild('firstName')
      )
      .valueChanges();
  }

  getUserProfilesCount(): Observable<number> {
    return this.rtdb
      .list<IUserProfile>('user-profiles')
      .valueChanges()
      .pipe(map((arr) => arr.length));
  }

  getUserProfile(uid: string): Observable<IUserProfile> {
    return this.rtdb
      .object<IUserProfile>(`user-profiles/${uid}`)
      .valueChanges();
  }

  verifyNedbankClient(uid: string) {
    const userRef = this.rtdb.object(`users/${uid}`);
    userRef.update({ isClientVerified: true });
    return userRef.update({
      modifiedAt: firebase.database.ServerValue.TIMESTAMP,
    });
  }

  unverifyNedbankClient(uid: string) {
    const userRef = this.rtdb.object(`users/${uid}`);
    userRef.update({ isClientVerified: false });
    return userRef.update({
      modifiedAt: firebase.database.ServerValue.TIMESTAMP,
    });
  }

  verifyUbuntuLandReg(uid: string) {
    const userRef = this.rtdb.object(`users/${uid}`);
    userRef.update({ isRegisteredOnUl: true });
    return userRef.update({
      modifiedAt: firebase.database.ServerValue.TIMESTAMP,
    });
  }

  unverifyUbuntuLandReg(uid: string) {
    const userRef = this.rtdb.object(`users/${uid}`);
    userRef.update({ isRegisteredOnUl: false });
    return userRef.update({
      modifiedAt: firebase.database.ServerValue.TIMESTAMP,
    });
  }

  verifyWalletConnection(uid: string) {
    const userRef = this.rtdb.object(`users/${uid}`);
    userRef.update({ isWalletConnected: true });
    return userRef.update({
      modifiedAt: firebase.database.ServerValue.TIMESTAMP,
    });
  }

  unverifyWalletConnection(uid: string) {
    const userRef = this.rtdb.object(`users/${uid}`);
    userRef.update({ isWalletConnected: false });
    return userRef.update({
      modifiedAt: firebase.database.ServerValue.TIMESTAMP,
    });
  }

  verifyTicketWinner(uid: string) {
    const userRef = this.rtdb.object(`users/${uid}`);
    userRef.update({ isNFTTicketWinner: true });
    return userRef.update({
      modifiedAt: firebase.database.ServerValue.TIMESTAMP,
    });
  }

  unverifyTicketWinner(uid: string) {
    const userRef = this.rtdb.object(`users/${uid}`);
    userRef.update({ isNFTTicketWinner: false });
    return userRef.update({
      modifiedAt: firebase.database.ServerValue.TIMESTAMP,
    });
  }

  verifyTicketRedemption(uid: string) {
    const userRef = this.rtdb.object(`users/${uid}`);
    userRef.update({ isTicketRedeemed: true });
    return userRef.update({
      modifiedAt: firebase.database.ServerValue.TIMESTAMP,
    });
  }

  unverifyTicketRedemption(uid: string) {
    const userRef = this.rtdb.object(`users/${uid}`);
    userRef.update({ isTicketRedeemed: false });
    return userRef.update({
      modifiedAt: firebase.database.ServerValue.TIMESTAMP,
    });
  }

  logNedbankClientVerification(verificationLog: INedbankClientVerification) {
    return this.rtdb.list(`client-verifications`).push({
      verifiedBy: verificationLog.verifiedBy,
      uid: verificationLog.uid,
      createdAt: firebase.database.ServerValue.TIMESTAMP,
    });
  }

  getNedbankClientVerifications(): Observable<INedbankClientVerification[]> {
    return this.rtdb
      .list<INedbankClientVerification>('client-verifications', (ref) =>
        ref.orderByChild('createdAt')
      )
      .valueChanges();
  }

  getNedbankClientVerificationRequests(): Observable<
    INedbankClientVerificationRequest[]
  > {
    return this.rtdb
      .list<INedbankClientVerificationRequest>(
        'client-verification-requests',
        (ref) => ref.orderByChild('createdAt')
      )
      .valueChanges();
  }
}
