import { IonicSafeString } from '@ionic/angular';
import { ImageObject } from './image';

export class Message {
  id?: string;
  uid: string;
  userName?: string;
  userAvatar?: string;
  roomId: string;
  message: string;
  image?: ImageObject;
  createdAt?: firebase.firestore.Timestamp;
  isEnabled?: boolean;
  type?: MessageType;
  parentId?: string;
  likeCount?: number;
  isAdminPost?: boolean;
  showAdminUser?: boolean;
  showPostTime?: boolean;
  showMessageActions?: boolean;
  linkUrl?: string;
  isExternal?: boolean;
  stickyUntil?: string;
}

export class MessageLike {
  id?: string;
  messageId: string;
  uid: string;
  createdAt?: firebase.firestore.Timestamp;
}

export enum MessageType {
  Post = 0,
  Reply = 1
}