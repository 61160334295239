import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';
import * as firebase from 'firebase/app';

import { Message, MessageType, MessageLike } from '../models/chat';

@Injectable()
export class ChatService {
  constructor(private db: AngularFirestore) {}

  getRoomChat(roomId: string, limit: number): Observable<Message[]> {
    return this.db
      .collection<Message>('chat', (ref) =>
        ref
          .where('isEnabled', '==', true)
          .where('roomId', '==', roomId)
          .where('type', '==', MessageType.Post)
          .orderBy('createdAt', 'desc')
          .limit(limit)
      )
      .valueChanges();
  }

  getAllRoomChat(roomId: string, limit: number): Observable<Message[]> {
    return this.db
      .collection<Message>('chat', (ref) =>
        ref
          .where('roomId', '==', roomId)
          .orderBy('createdAt', 'desc')
          .limit(limit)
      )
      .valueChanges();
  }

  getRoomChatCount(roomId: string): Observable<number> {
    return this.db
      .collection<Message>(`chat`, (ref) =>
        ref
          .where('isEnabled', '==', true)
          .where('roomId', '==', roomId)
          .where('type', '==', MessageType.Post)
          .orderBy('createdAt', 'desc')
      )
      .valueChanges()
      .pipe(map((arr) => arr.length));
  }

  createMessage(message: Message): Promise<any> {
    message.id = this.db.createId();
    //message.createdAt = firebase.firestore.Timestamp.now();
    const messageRef = this.db.doc(`chat/${message.id}`);
    return messageRef.set({
      ...JSON.parse(JSON.stringify(message)),
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
  }

  createAdminMessage(message: Message): Promise<any> {
    message.id = this.db.createId();
    //message.createdAt = firebase.firestore.Timestamp.now();
    const messageRef = this.db.doc(`chat/${message.id}`);
    if (message.stickyUntil) {
      return messageRef.set({
        ...JSON.parse(JSON.stringify(message)),
        createdAt: firebase.firestore.Timestamp.fromDate(new Date(message.stickyUntil)),
      });
    } else {
      return messageRef.set({
        ...JSON.parse(JSON.stringify(message)),
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
    }
  }

  getMessageReplies(messageId: string): Observable<Message[]> {
    return this.db
      .collection<Message>('chat', (ref) =>
        ref
          .where('isEnabled', '==', true)
          .where('parentId', '==', messageId)
          .where('type', '==', MessageType.Reply)
          .orderBy('createdAt', 'desc')
      )
      .valueChanges();
  }

  getMessageReplyCount(messageId: string): Observable<number> {
    return this.db
      .collection<Message>(`chat`, (ref) =>
        ref
          .where('isEnabled', '==', true)
          .where('parentId', '==', messageId)
          .where('type', '==', MessageType.Reply)
      )
      .valueChanges()
      .pipe(map((arr) => arr.length));
  }

  getMessageLikeCount(messageId: string): Observable<number> {
    return this.db
      .collection<MessageLike>(`likes`, (ref) =>
        ref.where('messageId', '==', messageId)
      )
      .valueChanges()
      .pipe(map((arr) => arr.length));
  }

  createMessageLike(messageLike: MessageLike) {
    messageLike.id = this.db.createId();
    const increment = firebase.firestore.FieldValue.increment(1);
    const messageRef = this.db.doc(`chat/${messageLike.messageId}`);
    const messageLikeRef = this.db.doc(`likes/${messageLike.id}`);
    messageRef.update({
      likeCount: increment,
    });
    return messageLikeRef.set({
      id: messageLike.id,
      uid: messageLike.uid,
      messageId: messageLike.messageId,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
  }

  checkMessageLikesByUser(messageLike: MessageLike): Promise<MessageLike[]> {
    return this.db
      .collection<MessageLike>(`likes`, (ref) =>
        ref
          .where('messageId', '==', messageLike.messageId)
          .where('uid', '==', messageLike.uid)
      )
      .valueChanges()
      .pipe(first())
      .toPromise();
  }

  deleteMessage(messageId: string) {
    const messageRef = this.db.doc<Message>(`chat/${messageId}`);
    return messageRef.update({
      isEnabled: false,
    });
  }

  hideMessage(messageId: string) {
    const messageRef = this.db.doc<Message>(`chat/${messageId}`);
    return messageRef.update({
      isEnabled: false,
    });
  }

  showMessage(messageId: string) {
    const messageRef = this.db.doc<Message>(`chat/${messageId}`);
    return messageRef.update({
      isEnabled: true,
    });
  }
}
