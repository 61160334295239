import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { MomentModule } from 'angular2-moment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TooltipModule } from 'ng2-tooltip-directive';
import { AngularCropperjsModule } from 'angular-cropperjs';
import { Ng2ImgMaxModule } from 'ng2-img-max';
import { HttpClientModule } from '@angular/common/http';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { environment } from 'src/environments/environment';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import {
  AngularFireAuthGuardModule,
  AngularFireAuthGuard,
} from '@angular/fire/auth-guard';
import { AngularFireDatabaseModule} from '@angular/fire/database';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AvatarModule } from 'ngx-avatar';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
//import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';

import {
  AuthService,
  ChatService,
  AgendaService,
  ContentService,
  ConfigService
} from './services';

import { AdminGuard } from './guards/admin.guard';
import { ModeratorGuard } from './guards/moderator.guard';

import { AgendaImageComponent } from './shared/agenda-image/agenda-image.component';
import { PostImageCreateComponent } from './shared/post-image-create/post-image-create.component';

@NgModule({
  declarations: [
    AppComponent,
    AgendaImageComponent,
    PostImageCreateComponent,
  ],
  entryComponents: [AgendaImageComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({ mode: 'md', sanitizerEnabled: false }),
    BrowserAnimationsModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireAuthGuardModule,
    AngularFireDatabaseModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    // AngularFirestoreModule.enablePersistence()
    NgxDatatableModule,
    AvatarModule,
    TooltipModule,
    AngularCropperjsModule,
    Ng2ImgMaxModule,
    HttpClientModule,
    MomentModule,
    ReactiveFormsModule,
    //OwlDateTimeModule,
    //OwlNativeDateTimeModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    AngularFireAuthGuard,
    AdminGuard,
    ModeratorGuard,
    AuthService,
    ChatService,
    AgendaService,
    ContentService,
    ConfigService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
