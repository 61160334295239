import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';

import * as firebase from 'firebase/app';

import { AgendaItem, AgendaGroup } from '../models/agenda';
import { ImageObject } from '../models/image';

@Injectable({
  providedIn: 'root',
})
export class AgendaService {
  storageRef: any = firebase.storage().ref();

  constructor(
    private db: AngularFirestore,
    private storage: AngularFireStorage
  ) {}

  getAgenda(): Observable<AgendaItem[]> {
    return this.db
      .collection<AgendaItem>('agenda', (ref) => ref.orderBy('start', 'desc'))
      .valueChanges();
  }

  getAgendaItem(id: string): Observable<AgendaItem> {
    return this.db.doc<AgendaItem>(`agenda/${id}`).valueChanges();
  }

  createAgendaItem(agendaItem: AgendaItem) {
    const agendaItemRef = this.db.doc(`agenda/${agendaItem.id}`);
    return agendaItemRef.set(JSON.parse(JSON.stringify(agendaItem)));
  }

  updateAgendaItem(agendaItem: AgendaItem) {
    console.log(agendaItem);
    const agendaItemRef = this.db.doc(`agenda/${agendaItem.id}`);
    return agendaItemRef.set(JSON.parse(JSON.stringify(agendaItem)));
  }

  deleteAgendaItem(agendaId: string) {
    // TODO: loop through and delete images to save storage
    const agendaItemRef = this.db.doc(`agenda/${agendaId}`);
    return agendaItemRef.delete();
  }

  deleteAgendaImage(image: ImageObject) {
    this.storage.storage.refFromURL(image.thumbnailImageUrl).delete();
    return this.storage.storage.refFromURL(image.imageUrl).delete();
  }

  createBookingCounter(agendaItem: AgendaItem) {
    const agendaBookingsCounterRef = this.db.doc(
      `booking-counter/${agendaItem.id}`
    );
    return agendaBookingsCounterRef.set({
      agendaItemId: agendaItem.id,
      bookingCount: 0,
      pax: agendaItem.pax,
    });
  }

  deleteBookingCounter(agendaItem: AgendaItem) {
    const agendaBookingsCounterRef = this.db.doc(
      `booking-counter/${agendaItem.id}`
    );
    return agendaBookingsCounterRef.delete();
  }

  updateBookingCounterPax(agendaItem: AgendaItem) {
    const agendaBookingsCounterRef = this.db.doc(
      `booking-counter/${agendaItem.id}`
    );
    return agendaBookingsCounterRef.update({
      pax: agendaItem.pax,
    });
  }

  getAgendaGroups(): Observable<AgendaGroup[]> {
    return this.db
      .collection<AgendaGroup>('agenda-groups', (ref) =>
        ref.orderBy('end', 'asc')
      )
      .valueChanges();
  }

  createAgendaGroup(agendaGroup: AgendaGroup) {
    agendaGroup.id = this.db.createId();
    const agendaGroupRef = this.db.doc(`agenda-groups/${agendaGroup.id}`);
    return agendaGroupRef.set(JSON.parse(JSON.stringify(agendaGroup)));
  }

  updateAgendaGroup(agendaGroup: AgendaGroup) {
    const agendaGroupRef = this.db.doc(`agenda-groups/${agendaGroup.id}`);
    return agendaGroupRef.update(JSON.parse(JSON.stringify(agendaGroup)));
  }

  deleteAgendaGroup(agendaGroup: AgendaGroup) {
    const agendaGroupRef = this.db.doc(`agenda-groups/${agendaGroup.id}`);
    return agendaGroupRef.delete();
  }
}
