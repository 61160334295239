import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable } from 'rxjs';

// import { IBanner } from '../models/banners';
// import { IPage } from '../models/pages';
// import { IPost } from '../models/feed-posts';
import { IConfig, ILiveScoringConfig, IFavouritePlayerConfig, IPaymentConfig, IPlayerConfig, IApiConfig, IHoleConfig, IRBBConfig, IMetaverseConfig, IScratchConfig } from '../models/config';

@Injectable({
	providedIn: 'root'
})
export class ConfigService {
	constructor(private db: AngularFireDatabase) {}

	getConfig(dbNode: string): Observable<IConfig> {
		return this.db.object<IConfig>('/config/' + dbNode).valueChanges();
	}

	getLiveScoringConfig(): Observable<ILiveScoringConfig> {
		return this.db.object<ILiveScoringConfig>('/config/live-scoring/').valueChanges();
	}

	getFavouritePlayerConfig(): Observable<IFavouritePlayerConfig> {
		return this.db.object<IFavouritePlayerConfig>('/config/favourite-player/').valueChanges();
  }

  getPaymentConfig(): Observable<IPaymentConfig> {
		return this.db.object<IPaymentConfig>('/config/payment/').valueChanges();
	}
	
	getPlayerConfig(): Observable<IPlayerConfig> {
		return this.db.object<IPlayerConfig>('/config/players/').valueChanges();
	}

	getApiConfig(): Observable<IApiConfig> {
		return this.db.object<IApiConfig>('/config/apis').valueChanges();
	}

	getHoleConfig(): Observable<IHoleConfig> {
		return this.db.object<IHoleConfig>('config/holes').valueChanges();
	}

	getRbbConfig(): Observable<IRBBConfig> {
		return this.db.object<IRBBConfig>('config/rbb').valueChanges();
	}

	getMetaverseConfig(): Observable<IMetaverseConfig> {
		return this.db.object<IMetaverseConfig>('config/metaverse').valueChanges();
	}

	getScratchConfig(): Observable<IScratchConfig> {
		return this.db.object<IScratchConfig>('config/scratch').valueChanges();
	}
}
