import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  constructor(public loadingCtrl: LoadingController) { }

  async showLoader(message: string) {
    const loading = await this.loadingCtrl.create({
      message: message,
      spinner: 'crescent',
      backdropDismiss: true
    });

    await loading.present();

    const { role, data } = await loading.onDidDismiss();
    console.log('Loading dismissed!');
  }

  hideLoader() {
    this.loadingCtrl.getTop().then(loader => { 
      if (loader) {
        setTimeout(() => {
          this.loadingCtrl.dismiss();
        }, 300); 
      }
    });
  }
}

