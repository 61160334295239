// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyAEdMJHOoxA-ULRBMNwygvDOCMQL8P7gVY',
    authDomain: 'nedbank-golf-challenge-2017.firebaseapp.com',
    databaseURL: 'https://nedbank-golf-challenge-2017.firebaseio.com',
    projectId: 'nedbank-golf-challenge-2017',
    storageBucket: 'nedbank-golf-challenge-2017.appspot.com',
    messagingSenderId: '16556570128',
    appId: '1:16556570128:web:7b88ebaf8e5c6fee107c95',
    measurementId: 'G-PT93P06HNF',
  },
  // firebase: {
  //   apiKey: 'AIzaSyAv1ARWkpxtb2u5eGdnmXJAQ5PjfPW89RA',
  //   authDomain: 'vodacom-live-event.firebaseapp.com',
  //   databaseURL: 'https://vodacom-live-event.firebaseio.com',
  //   projectId: 'vodacom-live-event',
  //   storageBucket: 'vodacom-live-event.appspot.com',
  //   messagingSenderId: '102452940627',
  //   appId: '1:102452940627:web:c737daa82cef8028555ba6',
  //   measurementId: 'G-43K86JFPCK',
  // },
  emailRegex: '[a-zA-Z0-9.-_-]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}',
  mobileRegex: '[+][0-9]{10,15}',
  baseUrl: 'http://localhost:8100',
};
