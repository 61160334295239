import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as Excel from 'exceljs';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  constructor() { }

  public async exportAsExcelFile(headers: any[], data: any[], excelFileName: string) {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('Report');
    worksheet.properties.defaultColWidth = 30;
    worksheet.addTable({
      name: 'Table',
      ref: 'A1',
      headerRow: true,
      totalsRow: false,
      style: {
        theme: 'TableStyleMedium9',
        showRowStripes: true,
      },
      columns: headers,
      rows: data,
    });

    const excelBuffer = await workbook.xlsx.writeBuffer();
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + ' ' + new Date().getTime() + EXCEL_EXTENSION);
  }
}
