import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { NavController } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { take, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class AdminGuard implements CanActivate {
  constructor(
    private auth: AngularFireAuth,
    private router: Router,
    private navCtrl: NavController
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.auth.authState.pipe(
      take(1),
      switchMap(async (authState) => {
        if (authState) {
          // check are user is logged in
          const token = await authState.getIdTokenResult();
          if (!token.claims.admin) {
            // check claims
            console.log('user is not admin!');
            this.navCtrl.navigateRoot('/unauthorized');
            //this.router.navigate(['/login']);
            return false;
          } else {
            return true;
          }
        } else {
          console.log('user is not admin');
          this.navCtrl.navigateRoot('/logout');
          //this.router.navigate(['/login']);
          return false;
        }
      })
    );
  }
}
