export class ImageObject {
  imageName: string;
  imageUrl: string;
  imageType: ImageType;
  thumbnailImageName?: string;
  thumbnailImageUrl?: string;
  thumbnailImageType?: ImageType;
}

export enum ImageType {
  Jpg = 0,
	Png = 1,
  Gif = 2,
  Svg = 3,
}

export enum ImageSize {
  Full = 0,
  Thumbnail = 1,
}